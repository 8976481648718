<template>
  <img alt="Vue logo" src="./assets/logo.png" width="200" height="200">
  <TodoApp :user="user"/>
</template>

<script>
import TodoApp from './components/TodoApp.vue'

export default {
  name: 'App',
  components: {
    TodoApp
  },
  data() {
    return {
      user: {
        username: 'roger.jakobsen@gmail.com',
        name: 'Roger Jakobsen'
      }
    };
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
